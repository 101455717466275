<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="添加pda"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
        <a-form-item label="pda编号" v-bind="validateInfos.code">
            <a-input
                    v-model:value="pdaVo.code"
                    :maxlength="50"
                    placeholder="pda编号"
                    allow-clear
            ></a-input>
        </a-form-item>
      <a-form-item label="设备名称" v-bind="validateInfos.name">
        <a-input
          v-model:value="pdaVo.name"
          :maxlength="20"
          placeholder="设备名称"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="状态" v-bind="validateInfos.state">
        <a-select
          v-model:value="pdaVo.state"
          placeholder="请选择"
          allow-clear
          :options="pdaStates"
        ></a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted,toRefs } from "vue";
import usePda from "@/hooks/maintenance/usePda";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { save } from "@/api/maintenance/pdaApi";

const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const { pdaVo, pdaStates } = usePda();
    const { pdaRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
        pdaVo,
        pdaRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的pda设备?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              name: pdaVo.name,
              code: pdaVo.code,
              state: pdaVo.state,
            };
            await save(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {

    });

    return {
      ...toRefs(pros),
      disabled,
      visible,
      afterClose,
      pdaRules,
      pdaVo,
      pdaStates,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
